const testUrls = [
    "/sykdommer/lunger/kols/kols-arsaker",
    "/sykdommer/hjernenervesystem/epilepsi/epilepsi-egenbehandling",
    "/sykdommer/kreft/rad-til-kreftpasienter/smaksforandring",
    "/sykdommer/hud/foflekker-pigmenterte-utslett/foflekkreft-oppfolging",
    "/sykdommer/hud/sar-hudskader-bitt/sarinfeksjon",
    "/sykdommer/kirurgi/behandlinger/nyrestein-perkutan-nefrolitotomi",
    "/sykdommer/infeksjoner/bakteriesykdommer/psoas-abscess",
    "/symptomer/infeksjoner/hand-og-fingerinfeksjoner-veiviser",
    "/sykdommer/blod/beinmargssykdommer/myelodysplastisk-syndrom",
    "/symptomer/nyrer-og-urinveier/nedsatt-nyrefunksjon-veiviser",
    "/sykdommer/magetarm/galleveier/primar-skleroserende-kolangitt",
    "/sykdommer/muskelskjelett/skulder-og-overarm/ac-ledd-skade",
    "/sykdommer/hud/papler-nuppete-utslett/hudkreft-basalcellekreft",
    "/sykdommer/hjernenervesystem/multippel-sklerose/multippel-sklerose-arsaker",
    "/sykdommer/hormoner-og-naring/beinskjorhet-osteoporose/beinskjorhet-oversikt",
    "/sykdommer/kreft/magetarm-kreft/magesekkreft",
    "/symptomer/infeksjoner/blodig-opphostet-slim-hva-kan-arsaken-vare",
    "/sykdommer/hjertekar/legemidler/acetylsalisylsyre",
    "/sykdommer/barn/oye/narsynthet",
    "/sykdommer/hjernenervesystem/migrene/migrene-behandling",
    "/sykdommer/kirurgi/undersokelser/koloskopi",
    "/sykdommer/hormoner-og-naring/skjoldkjertelsykdommer/skjoldkjertelen-kul",
    "/sykdommer/barn/undersokelser/eeg",
    "/sykdommer/hormoner-og-naring/diabetes-generelt/diabetes-og-alkohol",
    "/symptomsjekker/symptomer/hudinfeksjoner-bakterielle-veiviser",
    "/sykdommer/hjertekar/behandlinger/digitalisforgiftning",
    "/sykdommer/hjernenervesystem/undersokelser/myelografi-radikulografi",
    "/sykdommer/psykisk-helse/anoreksi/anoreksi-prognose",
    "/sykdommer/psykisk-helse/legemidler/antipsykotika",
    "/sykdommer/muskelskjelett/beinbrudd/talusbrudd",
    "/sykdommer/hud/rode-hudutslett/erythema-nodosum-knuterosen",
    "/symptomer/psyke-og-sinn/tvangstanker-og-tvangshandlinger-veiviser",
    "/sykdommer/blod/hiv-og-aids/hiv-infeksjon-symptomer",
    "/sykdommer/psykisk-helse/diverse/misofoni",
    "/sykdommer/hjernenervesystem/multippel-sklerose/multippel-sklerose-diagnostikk",
    "/sykdommer/hjernenervesystem/hjerneslag-og-blodninger/hjerneslag-utskrivning-av-pasient",
    "/sykdommer/hjernenervesystem/nerveskader/karpaltunnelsyndrom",
    "/familie/graviditet/svangerskap-og-fodsel/sykdommer/fodsel/fodselsdepresjon",
    "/sykdommer/psykisk-helse/barnepsykiatri/stamming",
    "/sykdommer/barn/oye/langsynthet",
    "/sykdommer/oye/netthinne-og-synsnervesykdom/skarpsynsvekkelse-makuladegenerasjon",
    "/sykdommer/barn/bein-og-ledd/spina-bifida-hos-barn",
    "/symptomer/orenesehals/horselstap-akutt-veiviser",
    "/familie/graviditet/svangerskap-og-fodsel/sykdommer/komplikasjoner-i-svangerskapet/svangerskapsblodning",
    "/sykdommer/kirurgi/undersokelser/rontgen-av-tynntarmen",
    "/sykdommer/muskelskjelett/kne/osteochondritis-dissecans",
    "/sykdommer/lunger/astma/astma-akutt-voksne",
    "/sykdommer/blod/hiv-og-aids/hiv-opportunistiske-infeksjoner",
    "/sykdommer/hormoner-og-naring/overvekt-og-kosthold/gastrisk-sleeve",
    "/sykdommer/barn/infeksjoner/hand-fot-og-munnsykdom",
    "/sykdommer/hud/pussholdig-utslett-ekskl-akne/sauekopper-orfvirussykdom",
    "/familie/graviditet/svangerskap-og-fodsel/sykdommer/komplikasjoner-i-svangerskapet/flerlingsvangerskap",
    "/sykdommer/infeksjoner/protozoer-og-ormer/filariasis-og-elefantsyke",
    "/sykdommer/barn/oye/oyekatarr-infeksios-konjunktivitt",
    "/sykdommer/muskelskjelett/kne/korsbandskade-fremre",
    "/sykdommer/oye/brytningsfeil-nedsatt-syn/laser-versus-linseoperasjon-ved-narsynthet",
    "/sykdommer/barn/infeksjoner/e-coli-tarminfeksjon",
    "/sykdommer/kreft/den-siste-tiden-hjemme/smerter-og-plager-kan-lindres",
    "/sykdommer/infeksjoner/diverse/hostemedisin-ved-luftveisinfeksjon",
    "/sykdommer/hjernenervesystem/ryggmargsskade/ryggmargsskade-oppfolging",
    "/familie/graviditet/svangerskap-og-fodsel/sykdommer/undersokelser/rhesus-uforlikelighet-i-svangerskapet",
    "/sykdommer/allergi/matvareallergi-og-intoleranse-kostrad/notter-mandler-og-fro",
    "/sykdommer/hjertekar/blodproppsykdom/blodpropp-i-leggen-dvt",
    "/sykdommer/psykisk-helse/diverse/munchausens-syndrom",
    "/sykdommer/magetarm/tykktarm/kostholdsrad-etter-tarmkirurgi",
    "/sykdommer/hormoner-og-naring/binyresykdommer/addison-krise",
    "/sykdommer/lunger/kols/kols-egenbehandling",
    "/symptomer/psyke-og-sinn/uro-forvirring-veiviser",
    "/sykdommer/barn/hjertefeil-medfodte/transposisjon-av-store-arterier",
    "/sykdommer/blod/anemier/pernisios-anemi",
    "/sykdommer/barn/undersokelser/ultralyd-av-bukhulen",
    "/familie/graviditet/svangerskap-og-fodsel/sykdommer/komplikasjoner-i-svangerskapet/svangerskapsforgiftning-preeklampsi",
    "/sykdommer/hud/rode-hudutslett/utslett-rundt-munnen-perioral-dermatitt",
    "/sykdommer/hjertekar/undersokelser/ekkokardiografi",
    "/sykdommer/barn/bein-og-ledd/habituell-tagang",
    "/sykdommer/mann/seksuelle-tema/penisimplantat-ved-ereksjonssvikt",
    "/sykdommer/hud/foflekker-pigmenterte-utslett/foflekkreft-malignt-melanom",
    "/symptomer/barn/tung-pust-hos-barn-hva-kan-arsaken-vare",
    "/sykdommer/hjernenervesystem/hodepiner/cluster-hodepine",
    "/sykdommer/psykisk-helse/diverse/tvangslidelse-ulike-symptomer",
    "/sykdommer/hjertekar/hoyt-blodtrykk-hypertensjon/bivirkninger-av-blodtrykksmedisin",
    "/sykdommer/barn/infeksjoner/rotavirusinfeksjon",
    "/familie/graviditet/svangerskap-og-fodsel/sykdommer/komplikasjoner-i-svangerskapet/morkakelosning-abruptio-placenta",
    "/sykdommer/barn/nervesystemet/cerebral-parese",
    "/sykdommer/infeksjoner/hiv-og-aids/hiv-og-aids-oversikt",
    "/sykdommer/barn/barnepsykiatri/angstlidelse-hos-barn",
    "/sykdommer/barn/bulimi/bulimi-forekomst",
    "/sykdommer/kreft/lindrende-behandling/smertebehandling",
    "/sykdommer/magetarm/lever/budd-chiaris-syndrom",
    "/sykdommer/muskelskjelett/albue-underarm-hand/slimposebetennelse-olecranon-bursitt",
    "/sykdommer/kreft/lunger-kreft/lungekreft",
    "/sykdommer/infeksjoner/kjonnssykdommer/herpes-genitalis",
    "/sykdommer/kreft/rad-til-kreftpasienter/for-deg-som-spiser-lite",
    "/sykdommer/hormoner-og-naring/gastrisk-bypass/gastrisk-bypass-tidlige-kostrad",
    "/sykdommer/blod/ulike-blodsykdommer/hypereosinofile-syndromer",
    "/sykdommer/kvinne/ulike-sykdommer/kroniske-underlivssmerter",
    "/symptomer/hjerne-og-nervesystem/skjelving-veiviser",
    "/sykdommer/kirurgi/undersokelser/ercp-endoskopi-av-gallegangen",
    "/sykdommer/kirurgi/anestesi/bedovelse-oversikt",
    "/sykdommer/barn/arvelige-og-medfodte-tilstander/ehlers-danlos-syndrom",
    "/sykdommer/kvinne/ulike-sykdommer/gynekologiske-fistler",
    "/sykdommer/muskelskjelett/legg-ankel-fot/ankel-ustabil",
    "/sykdommer/hud/har-og-hodebunn/helserisikoer-hos-personer-med-rodt-har",
    "/symptomer/kreft/blod-i-avforingen-ikke-synlig-veiviser",
    "/sykdommer/hormoner-og-naring/skjoldkjertelsykdommer/skjoldkjertelkreft",
    "/sykdommer/lunger/infeksjoner/klamydia-lungebetennelse",
    "/sykdommer/barn/barnepsykiatri/selvmordsforsok-hos-barn-og-ungdom",
    "/sykdommer/hjertekar/blodproppsykdom/blodpropp-i-lungene-lungeemboli",
    "/sykdommer/hjertekar/koronarsykdom/hjerteinfarkt-behandling",
    "/sykdommer/oye/netthinne-og-synsnervesykdom/venetrombose-i-oyet",
    "/sykdommer/sjeldne-tilstander/w/williams-syndrom",
    "/sykdommer/magetarm/inflammatorisk-tarmsykdom/crohns-sykdom-legemidler",
    "/sykdommer/hjertekar/klaffesykdom/bikuspid-aortaklaff",
    "/sykdommer/hormoner-og-naring/skjoldkjertelsykdommer/lavt-stoffskifte-hypotyreose",
    "/sykdommer/nyrerurinveier/kronisk-nyresykdom/kronisk-nyresykdom-prognose",
    "/sykdommer/hormoner-og-naring/diabetes-generelt/senkomplikasjoner-diabetes",
    "/sykdommer/kirurgi/magetarmsykdommer/dumping-syndrom",
    "/sykdommer/hud/diverse/dermatomyositt",
    "/sykdommer/lunger/undersokelser/bronkoskopi",
    "/sykdommer/hjertekar/hjertearytmier/hjertestarter-implanterbar",
    "/sykdommer/hjernenervesystem/nevrokirurgi/hjernesvulst-oversikt",
    "/sykdommer/hud/skjellende-utslett-ekskl-eksem-og-psoriasis/pityriasis-versicolor",
    "/sykdommer/hud/kloende-lidelser/lopper",
    "/sykdommer/muskelskjelett/rygg-nakke-bryst/korsryggsmerter-akutt",
    "/sykdommer/hud/rode-hudutslett/candidiasis-i-huden",
    "/sykdommer/hjertekar/blodaresykdom/trange-blodarer-i-beina-claudicatio",
    "/sykdommer/barn/kreftsykdommer/wilms-svulst",
    "/sykdommer/lunger/undersokelser/lungeutredning",
    "/symptomer/kirurgi/blodig-oppkast-og-blodig-diare-veiviser",
    "/sykdommer/kirurgi/plastisk-kirurgi/harfjerning-med-laser",
    "/sykdommer/kreft/a-dele-en-krise-i-livet/krisereaksjoner",
    "/sykdommer/allergi/allergiforebygging/vaksinering-mot-allergi",
    "/sykdommer/magetarm/galleveier/primar-biliar-cirrhose",
    "/sykdommer/infeksjoner/protozoer-og-ormer/giardiasis",
    "/sykdommer/barn/bulimi/bulimi-prognose",
    "/sykdommer/infeksjoner/diverse/cytomegalovirus",
    "/symptomer/barn/forsinket-pubertet-hva-kan-arsaken-vare",
    "/sykdommer/muskelskjelett/legg-ankel-fot/ankelleddsslitasje-ankelartrose",
    "/sykdommer/eldre/hjelpeverge/verge-konsekvenser",
    "/sykdommer/barn/undersokelser/pet",
    "/sykdommer/magetarm/magesekk/magesar",
    "/sykdommer/nyrerurinveier/akutt-nyresvikt/akutt-nyresvikt-behandling",
    "/sykdommer/orenesehals/ytre-ore-og-oregang/nekrotiserende-ekstern-otitt",
    "/sykdommer/barn/arvelige-og-medfodte-tilstander/tuberos-sklerose",
    "/sykdommer/hjertekar/hjertearytmier/syk-sinus-syndrom",
    "/sykdommer/hjertekar/kost-trening-og-hjerte/det-aldrende-hjertet",
    "/sykdommer/hjernenervesystem/hjernehinnebetennelse/hjernehinnebetennelse-meningokokk",
    "/sykdommer/nyrerurinveier/urinlekkasje/urinlekkasje-ved-stress",
    "/sykdommer/blod/blodningssykdommer/disseminert-intravaskular-koagulasjon",
    "/symptomer/barn/sovnvansker-hos-barn-hva-kan-arsaken-vare",
    "/sykdommer/kreft/vis-kreftvett/kreftforebygging-med-et-sunt-kosthold",
    "/sykdommer/infeksjoner/tuberkulose/tuberkulose-risikofaktorer",
    "/sykdommer/barn/omsorgssvikt-og-barnemishandling/barnemishandling-tiltak",
    "/sykdommer/muskelskjelett/ulike-muskelsykdommer/muskellosjesyndrom-akutt",
    "/sykdommer/hud/sar-hudskader-bitt/leggsar-venose",
    "/sykdommer/hjertekar/koronarsykdom/angina-pectoris-nar-soke-hjelp",
    "/sykdommer/muskelskjelett/legg-ankel-fot/tarsal-tunnel-syndrom",
    "/sykdommer/infeksjoner/bakteriesykdommer/botulisme",
    "/sykdommer/lunger/astma/astma-og-graviditet",
    "/sykdommer/hormoner-og-naring/binyresykdommer/feokromocytom",
    "/sykdommer/kvinne/abort/medikamentell-abort",
    "/sykdommer/infeksjoner/bakteriesykdommer/lemierres-syndrom",
    "/sykdommer/kirurgi/magetarmsykdommer/brokk-inneklemt",
    "/sykdommer/kreft/undersokelser/ct-av-livmoren-og-egglederne",
    "/sykdommer/magetarm/inflammatorisk-tarmsykdom/crohns-sykdom",
    "/sykdommer/hjernenervesystem/ryggmargssykdommer/syringomyeli",
    "/sykdommer/orenesehals/behandling/orekirurgi",
    "/sykdommer/barn/infeksjoner/after",
    "/sykdommer/barn/blod-og-lymfe/hovne-lymfeknuter",
    "/sykdommer/hjertekar/klaffesykdom/mitralinsuffisiens",
    "/sykdommer/infeksjoner/diverse/penicillin",
    "/symptomer/barn/besvimelse-hos-barn-og-unge-hva-kan-arsaken-vare",
    "/sykdommer/infeksjoner/tuberkulose/tuberkulose-lunge-oversikt",
    "/sykdommer/hormoner-og-naring/diabetes-type-1/diabetes-i-kropp-og-sinn-intervju",
    "/symptomer/barn/bilyd-over-hjertet-hos-barn-hva-kan-arsaken-vare",
    "/sykdommer/eldre/demens/demens-hvorfor-blir-noen-demente",
    "/sykdommer/eldre/ulike-sykdommer/krum-rygg-hos-eldre",
    "/sykdommer/orenesehals/svelget-nedre-del/strupekreft-larynkskreft",
    "/sykdommer/psykisk-helse/legemidler/serotonin-syndrom",
    "/sykdommer/eldre/demens/demens-medisiner-mot",
    "/sykdommer/muskelskjelett/ulike-muskelsykdommer/polymyalgia-revmatika",
    "/sykdommer/magetarm/lever/gilberts-syndrom",
    "/sykdommer/sjeldne-tilstander/l/lysosomale-sykdommer",
    "/sykdommer/psykisk-helse/depresjon/depresjon-diagnostikk"
]

export default function shouldAbTestPaging(routeUrl: string) {
    if (routeUrl.endsWith("/")) routeUrl = routeUrl.slice(0, -1)
    const result = testUrls.some(x => routeUrl.endsWith(x) || x === routeUrl)
    return result
}